import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup, createSelectDecorationLocationPopup } from '../actions/popup';

import { getOrderByNumber } from '../selectors';

import { getImageSrc } from '../utils';
import { BASE_ZINDEX } from '../popup-factory';

import Img from './Img';
import { isValidExtLocationId } from '../helpers/ps_locations';

class SelectDecorationProductPopup extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { order, item_id, onClosePopup, onCreateSelectDecorationLocationPopup, index } = this.props;

    const items = ['ESTIMATE', 'SALES ORDER', 'INVOICE'].includes(order.order_type) ?
      order.items.filter(oi => !!oi.item_locations.length && oi.item_id !== item_id) :
      order.items.filter(oi => 'OPTION' === oi.parent_type && !!oi.item_locations.length && oi.item_id !== item_id);

    const this_item = (['ESTIMATE', 'SALES ORDER', 'INVOICE'].includes(order.order_type) ?
      order.items.filter(oi => oi.item_id === item_id) :
      order.items.filter(oi => 'OPTION' === oi.parent_type && oi.item_id === item_id))[0];

    return (
      <div id="select-decoration-product-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Which product would you like to copy from?</h3>
            <a className="alert button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
          </div>
        </div>
        <div className="row popup-content">
          {items.map(i => {
            const item_locations = i.item_locations.filter(il => !isValidExtLocationId(il.ext_location_id) || this_item.parent_id === i.parent_id);
            if (!item_locations.length) {
              return false;
            }
            return (
              <div className="medium-6 large-4 columns product" key={i.item_id} onClick={e => {e.preventDefault(); onCreateSelectDecorationLocationPopup(item_locations, item_id); }}>
                <div className="medium-6 large-4 columns">
                  <Img src={getImageSrc(i.item_images.map(ii => ii.image)[0], 'small')} className="product-image" />
                </div>
                <div className="medium-6 large-8 columns columns">
                  <h5 style={{fontWeight: 'bold', marginBottom: '0.5rem', color: '#5ca3b6'}}>{i.item_name.length > 25 ? i.item_name.substring(0, 25) + '...' : i.item_name}</h5>
                  <div className="artwork-container">
                    {item_locations.reduce((a, il) => a.concat(il.artworks), []).map(a =>
                      <Img key={a.artwork_id} src={getImageSrc(a.image)} />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = getOrderByNumber(state, { form_number: ownProps.order_number, order_type: ownProps.order_type });
  const item_id = ownProps.item_id;
  return {
    order,
    item_id
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateSelectDecorationLocationPopup: (item_locations, item_id) => {
      dispatch(createSelectDecorationLocationPopup(item_locations, item_id));
    }
  };
};

const ConnectedSelectDecorationProductPopup = connect(mapStateToProps, mapDispatchToProps)(SelectDecorationProductPopup);
export default ConnectedSelectDecorationProductPopup;

